import {library, config, icon} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faFacebookSquare,
  faFacebookF,
  faGoogle,
  faInstagram,
  faLinkedin,
  faPagelines,
} from '@fortawesome/free-brands-svg-icons';

import {
  faBell,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faFilePdf,
  faHome,
  faHomeAlt,
  faPlus,
  faPlusCircle,
  faSignInAlt,
  faTimes,
  faUpload,
  faUserHardHat,
  faTachometerAlt,
  faStar as falStar,
  faCaretUp,
  faCaretDown,
  faUser,
  faClipboard as falClipboard,
  faEnvelope as falEnvelope,
  faPhone as falPhone,
  faMapMarkerAlt as falMapMarkerAlt,
  faClock as falClock,
  faBox,
  faUsers,
  faSearchPlus,
  faQrcode,
  faCode,
  faHeart,
  faFileImport,
} from '@fortawesome/pro-light-svg-icons';

import {
  faBold,
  faCubes,
  faDigging,
  faEnvelope,
  faHeart as fasHeart,
  faInfoCircle,
  faItalic,
  faLayerPlus,
  faLink,
  faList,
  faMap,
  faPhone,
  faRecycle,
  faSearch,
  faShare,
  faUnderline,
  faSpinner,
  faTimes as fasTimes,
  faTrashAlt,
  faExternalLinkAlt,
  faClipboard,
  faStar,
  faArrowLeft,
  faArrowRight,
  faRedo,
  faThLarge,
  faTachometerAlt as fasTachometerAlt,
  faAngleLeft,
  faAngleRight,
  faMapMarkedAlt,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCheckSquare,
  faClipboardList,
  faComment,
  faEdit,
  faCopy,
  faEye,
  faFingerprint,
  faInfoCircle as farInfoCircle,
  faSignIn,
  faSortDown,
  faSortUp,
  faHeart as farHeart,
  faSquare,
  faCheck,
  faSort,
  faTrash,
  faPaperPlane,
  faMapMarkerAlt,
  faUser as farUser,
  faPhone as farPhone,
  faEnvelope as farEnvelope,
  faBox as farBox,
  faClock as farClock,
} from '@fortawesome/pro-regular-svg-icons';

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    config.autoAddCss = false;

    library.add(
      farClock,
      farBox,
      farPhone,
      farUser,
      farEnvelope,
      faPaperPlane,
      faBell,
      faBold,
      faCheckSquare,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      faClipboardList,
      faCog,
      faComment,
      faCopy,
      faCubes,
      faDigging,
      faEdit,
      faEye,
      faEnvelope,
      faFacebookF,
      faFingerprint,
      faHeart,
      farHeart,
      faGoogle,
      fasHeart,
      faHome,
      faHomeAlt,
      faFacebookSquare,
      faFilePdf,
      faFingerprint,
      faInfoCircle,
      faUser,
      farInfoCircle,
      faInstagram,
      faItalic,
      faLayerPlus,
      faLink,
      faLinkedin,
      faList,
      faMap,
      faMapMarkedAlt,
      faPagelines,
      faPhone,
      faPlus,
      faPlusCircle,
      faRecycle,
      faSearch,
      faShare,
      faSignIn,
      faSignInAlt,
      faSortDown,
      faSortUp,
      faSquare,
      faTimes,
      fasTimes,
      faTrashAlt,
      faUpload,
      faUnderline,
      faUserHardHat,
      faTachometerAlt,
      faSpinner,
      faTrashAlt,
      faExternalLinkAlt,
      faStar,
      falStar,
      faCaretUp,
      faCaretDown,
      faArrowLeft,
      faArrowRight,
      faCheck,
      faRedo,
      faThLarge,
      faAngleLeft,
      faAngleRight,
      faSort,
      faChevronLeft,
      faEdit,
      faTrash,
      faClipboard,
      fasTachometerAlt,
      faBox,
      faUsers,
      faHeart,
      faSearchPlus,
      faQrcode,
      faCode,
      falClipboard,
      falEnvelope,
      falPhone,
      falMapMarkerAlt,
      falClock,
      faMapMarkerAlt,
      faFileImport,
    );

    /* ------------------
        CUSTOM ICONS
    ------------------ */

    const faBenchLamp = icon({
      prefix: 'fas',
      iconName: 'bench-lamp',
      icon: [
        616.6,
        541.3,
        [],
        '',
        'M381.9,541.3H10c-5.52,0-10-4.48-10-10s4.48-10,10-10H110.5v-54.6h-36.8c-5.52,0-10-4.48-10-10s4.48-10,10-10h36.8v-121.2c0-5.52,4.48-10,10-10H381.05c7.94,0,10,5.8,10.45,7.58,.18,.72,.28,1.46,.3,2.21,.12,5.35,.14,66.95,.13,121.42h38.87c5.52,0,10,4.48,10,10s-4.48,10-10,10h-38.87c0,23.09-.01,43.17-.02,54.6h130.39c0-35.04,0-149.47,0-256.47,0-108.31,0-209.01,0-212.11,0-18.04-14.67-32.71-32.7-32.71h-110.6c-17.98,0-32.6,14.62-32.6,32.6v40.86c1.52,.04,3.06,.09,4.63,.14h.14c12.67,.61,22.67,4.98,29.72,12.99,8.96,10.18,12.53,25.48,10.89,46.77-.25,9.99-8.45,18.04-18.49,18.04h-73.9c-10.08,0-18.3-8.1-18.5-18.14-1.4-23.24,2.14-37.88,11.44-47.45,8.28-8.51,19.43-11.51,34.06-12.29V52.6c0-29,23.6-52.6,52.6-52.6h110.6c29.06,0,52.7,23.64,52.7,52.71,0,3.1,0,103.81,0,212.12,0,107.01,0,221.44,0,256.47h64.3c5.52,0,10,4.48,10,10s-4.48,10-10,10h-224.7Zm-251.4-20h241.41c0-11.39,.02-31.49,.02-54.6H130.5v54.6Zm0-74.6h241.43c0-15.04,0-30.62,0-45.4H130.5v45.4Zm0-65.4h241.42c0-19.32-.03-35.82-.06-45.8-41.36,0-199.82,0-241.36,0v45.8Zm170.33-229.9h71.06c1.12-15.18-.9-25.8-5.99-31.58-3.38-3.83-8.48-5.87-15.61-6.22-3.9-.13-7.62-.24-11.12-.28-1.77,.51-3.8,.5-5.57,0-12.78,.14-22.14,1.55-26.92,6.45-4.85,4.99-6.77,15.34-5.85,31.64Z',
      ],
    });

    library.add(faBenchLamp);

    nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
  },
});
