import { default as _91slug_93Cc8WOWYAapMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/aanbieders/[slug].vue?macro=true";
import { default as indexD5VoTCv1qOMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/aanbieders/index.vue?macro=true";
import { default as _91uuid_93oiCjhjfwETMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/favorieten/[uuid].vue?macro=true";
import { default as indexbZQbysB3zuMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/favorieten/index.vue?macro=true";
import { default as _91uuid_937KvzGVJAYFMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/gebruikers/[uuid].vue?macro=true";
import { default as addZ2INLDjVtLMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/gebruikers/add.vue?macro=true";
import { default as indexlJaCTROCdRMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/gebruikers/index.vue?macro=true";
import { default as importRMapOfy0WEMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/import.vue?macro=true";
import { default as indexwG957ABAkmMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93a1H6hP3q83Meta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/materialen/[slug].vue?macro=true";
import { default as indexChiuNqH9cEMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/materialen/index.vue?macro=true";
import { default as nieuwe9zJQUcDalMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/materialen/nieuw.vue?macro=true";
import { default as profieleLGNslE56EMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/profiel.vue?macro=true";
import { default as _91slug_93lTtM5Jv31PMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/projecten/[slug].vue?macro=true";
import { default as index6AOhzA0S3RMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/projecten/index.vue?macro=true";
import { default as nieuwacynBoQkeuMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/projecten/nieuw.vue?macro=true";
import { default as _91uuid_93Gyu43WUn9cMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/toegang/[uuid].vue?macro=true";
import { default as widget98g8nu1gf4Meta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/widget.vue?macro=true";
import { default as zoekopdrachtenp1I9bx4fj7Meta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/zoekopdrachten.vue?macro=true";
import { default as help06DugwY0JlMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/help.vue?macro=true";
import { default as indexQIkUoyhwcCMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/index.vue?macro=true";
import { default as inloggenswyH7a5ZnkMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/inloggen.vue?macro=true";
import { default as _91_46_46_46slug_93VVwt3jBQbIMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/materialen/[...slug].vue?macro=true";
import { default as indexDqniw2hvMvMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/materialen/index.vue?macro=true";
import { default as _91uuid_939vShepgxXcMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/notificaties/unsubscribe/[uuid].vue?macro=true";
import { default as authorizeMePUcYvhaTMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/oauth/authorize.vue?macro=true";
import { default as offerte_45aanvragenwOhziiQzoCMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/offerte-aanvragen.vue?macro=true";
import { default as _91slug_93ISQKXfj5dmMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/project/[slug].vue?macro=true";
import { default as _91token_93uoVT0UZxllMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_936IP6YYi4XTMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexI4rgkh3sbYMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91id_93855qMeoVAAMeta } from "/data/www/marktplaats.insert.nl/insert/nuxt/pages/widget/[id].vue?macro=true";
export default [
  {
    name: "aanbieders-slug",
    path: "/aanbieders/:slug()",
    meta: _91slug_93Cc8WOWYAapMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/aanbieders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "aanbieders",
    path: "/aanbieders",
    meta: indexD5VoTCv1qOMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/aanbieders/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-favorieten-uuid",
    path: "/dashboard/favorieten/:uuid()",
    meta: _91uuid_93oiCjhjfwETMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/favorieten/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-favorieten",
    path: "/dashboard/favorieten",
    meta: indexbZQbysB3zuMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/favorieten/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gebruikers-uuid",
    path: "/dashboard/gebruikers/:uuid()",
    meta: _91uuid_937KvzGVJAYFMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/gebruikers/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gebruikers-add",
    path: "/dashboard/gebruikers/add",
    meta: addZ2INLDjVtLMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/gebruikers/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gebruikers",
    path: "/dashboard/gebruikers",
    meta: indexlJaCTROCdRMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/gebruikers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-import",
    path: "/dashboard/import",
    meta: importRMapOfy0WEMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexwG957ABAkmMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-materialen-slug",
    path: "/dashboard/materialen/:slug()",
    meta: _91slug_93a1H6hP3q83Meta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/materialen/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-materialen",
    path: "/dashboard/materialen",
    meta: indexChiuNqH9cEMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/materialen/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-materialen-nieuw",
    path: "/dashboard/materialen/nieuw",
    meta: nieuwe9zJQUcDalMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/materialen/nieuw.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profiel",
    path: "/dashboard/profiel",
    meta: profieleLGNslE56EMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/profiel.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-projecten-slug",
    path: "/dashboard/projecten/:slug()",
    meta: _91slug_93lTtM5Jv31PMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/projecten/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-projecten",
    path: "/dashboard/projecten",
    meta: index6AOhzA0S3RMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/projecten/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-projecten-nieuw",
    path: "/dashboard/projecten/nieuw",
    meta: nieuwacynBoQkeuMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/projecten/nieuw.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-toegang-uuid",
    path: "/dashboard/toegang/:uuid()",
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/toegang/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-widget",
    path: "/dashboard/widget",
    meta: widget98g8nu1gf4Meta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/widget.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-zoekopdrachten",
    path: "/dashboard/zoekopdrachten",
    meta: zoekopdrachtenp1I9bx4fj7Meta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/dashboard/zoekopdrachten.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    meta: help06DugwY0JlMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexQIkUoyhwcCMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inloggen",
    path: "/inloggen",
    meta: inloggenswyH7a5ZnkMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: "materialen-slug",
    path: "/materialen/:slug(.*)*",
    meta: _91_46_46_46slug_93VVwt3jBQbIMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/materialen/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "materialen",
    path: "/materialen",
    meta: indexDqniw2hvMvMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/materialen/index.vue").then(m => m.default || m)
  },
  {
    name: "notificaties-unsubscribe-uuid",
    path: "/notificaties/unsubscribe/:uuid()",
    meta: _91uuid_939vShepgxXcMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/notificaties/unsubscribe/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/oauth/authorize.vue").then(m => m.default || m)
  },
  {
    name: "offerte-aanvragen",
    path: "/offerte-aanvragen",
    meta: offerte_45aanvragenwOhziiQzoCMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/offerte-aanvragen.vue").then(m => m.default || m)
  },
  {
    name: "project-slug",
    path: "/project/:slug()",
    meta: _91slug_93ISQKXfj5dmMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/project/[slug].vue").then(m => m.default || m)
  },
  {
    name: "wachtwoord-instellen-token",
    path: "/wachtwoord-instellen/:token()",
    meta: _91token_93uoVT0UZxllMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: "wachtwoord-vergeten-token",
    path: "/wachtwoord-vergeten/:token()",
    meta: _91token_936IP6YYi4XTMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/wachtwoord-vergeten/[token].vue").then(m => m.default || m)
  },
  {
    name: "wachtwoord-vergeten",
    path: "/wachtwoord-vergeten",
    meta: indexI4rgkh3sbYMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/wachtwoord-vergeten/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-id",
    path: "/widget/:id()",
    meta: _91id_93855qMeoVAAMeta || {},
    component: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/pages/widget/[id].vue").then(m => m.default || m)
  }
]