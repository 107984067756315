import revive_payload_client_odLC72XLij from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_aK5TZ0xPtq from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YwmBzUGaBK from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6eDunBD3pp from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_terser@5.3_wt6mwhmtjwgwldz2d6srjsetc4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_JVq7puv4CK from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ofV47SQXky from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_B9Vh5PwhvC from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aVYKYSJGFG from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_d7P3id4Aht from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.5.4_vue@3.5.3_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/data/www/marktplaats.insert.nl/insert/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_g7syHayjKO from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8sB9voRFxz from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_5U9XMHTkev from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.3_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_fZWLKIiUlQ from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.3_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/marktplaats.insert.nl/insert/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_zzQrbLPshq from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_react@_pfexykbyxilkgimusywiq2xijq/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_AzATl0knBK from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+core@7.25.2_reac_wadxvwj4acgyvaoc7pvfuyb7pq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_l8N1B45Fxv from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node_aezatjuldch5ux2ylztm3cdaaq/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_xZn6ge5xXp from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_MKAR6gSn3l from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.9_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3_webpack@5.94.0_esbuild@0.23.1_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import errors_aPRLuyRemT from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.13_op5zjsnrevxet44euqkvejsbyi/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_mcwWDwJjMX from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.13_op5zjsnrevxet44euqkvejsbyi/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_IXc2iIShw0 from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_lkx5sjnhhbrkv73b5ok7hn4mea/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_aC3pxNSlhi from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_lkx5sjnhhbrkv73b5ok7hn4mea/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_9xIKxk4k8o from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0__vd7pfhgjsd4fknusi5erw4qkm4/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_UmBC4ICNfE from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0__vd7pfhgjsd4fknusi5erw4qkm4/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import directives_8CcCirWtnE from "/data/www/marktplaats.insert.nl/insert/nuxt/plugins/directives.ts";
import fontawesome_cn2c4tOOHz from "/data/www/marktplaats.insert.nl/insert/nuxt/plugins/fontawesome.ts";
import toast_ysMjUcU7eJ from "/data/www/marktplaats.insert.nl/insert/nuxt/plugins/toast.ts";
import tooltip_Di9mlKVbnI from "/data/www/marktplaats.insert.nl/insert/nuxt/plugins/tooltip.ts";
export default [
  revive_payload_client_odLC72XLij,
  unhead_aK5TZ0xPtq,
  router_YwmBzUGaBK,
  _0_siteConfig_6eDunBD3pp,
  payload_client_JVq7puv4CK,
  navigation_repaint_client_ofV47SQXky,
  check_outdated_build_client_B9Vh5PwhvC,
  chunk_reload_client_aVYKYSJGFG,
  plugin_vue3_d7P3id4Aht,
  components_plugin_KR1HBZs4kY,
  prefetch_client_g7syHayjKO,
  plugin_8sB9voRFxz,
  switch_locale_path_ssr_5U9XMHTkev,
  i18n_fZWLKIiUlQ,
  formkitPlugin_pZqjah0RUG,
  plugin_zzQrbLPshq,
  plugin_AzATl0knBK,
  plugin_l8N1B45Fxv,
  plugin_xZn6ge5xXp,
  plugin_MKAR6gSn3l,
  errors_aPRLuyRemT,
  i18n_mcwWDwJjMX,
  apollo_IXc2iIShw0,
  sofie_required_version_aC3pxNSlhi,
  apollo_9xIKxk4k8o,
  auth_UmBC4ICNfE,
  directives_8CcCirWtnE,
  fontawesome_cn2c4tOOHz,
  toast_ysMjUcU7eJ,
  tooltip_Di9mlKVbnI
]