<template>
  <NuxtLayout name="full-width-collapsed">
    <main class="mb-12 mt-24 flex flex-col items-center sm:mt-6 md:mt-24">
      <template v-if="page404">
        <h1 class="mb-6 text-3xl text-primary">
          {{ page404.title }}
        </h1>
        <SofieText
          class="content pb-10"
          :value="'tekst' in page404.template! ? page404.template.tekst : ''"
        />

        <NuxtLink
          :to="{name: 'index'}"
          class="btn btn-secondary"
        >
          Terug naar home
        </NuxtLink>
      </template>

      <template v-else-if="error.statusCode === 403">
        <h1 class="mb-6 text-3xl text-primary">
          Geen toegang
        </h1>
        <NuxtLink
          v-if="!user"
          :to="{name: 'inloggen', query: {destination: route.fullPath}}"
          class="btn btn-secondary"
        >
          Ga naar login pagina
        </NuxtLink>
        <template v-else>
          <template v-if="!checkAccessRequest">
            <p class="pb-10">
              U heeft geen toegang tot deze pagina. {{ accessData ? 'Vraag hier toegang aan.' : '' }}
            </p>
            <button
              class="btn btn-secondary"
              type="button"
              @click="requestAccess()"
            >
              Vraag toegang
            </button>
          </template>
          <p v-else>
            Je aanvraag voor toegang tot deze pagina is verstuurd.
          </p>
        </template>
      </template>

      <template v-else>
        <h1 class="mb-6 text-3xl text-primary">
          Er is een fout opgetreden
        </h1>
        <div class="content pb-10">
          Onze excuses voor het ongemak.
        </div>
      </template>
    </main>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type {NuxtError} from '#app';

const props = defineProps<{
  error: NuxtError,
}>();

const route = useRoute();
const user = useSofieUserWhenAuthenticated();

onMounted(() => {
  if (!user.value && props.error.statusCode === 403) {
    navigateTo({name: 'inloggen', query: {destination: route.fullPath}});
  }
});

const {result: pageResult} = usePageQuery({
  segments: '404',
}, () => ({
  enabled: props.error.statusCode === 404 || props.error.statusCode === 410,
}));
const page404 = computed(() => pageResult.value?.page);

useSeoMeta({
  title: computed(() => {
    if (props.error.statusCode === 403) {
      return 'Geen toegang';
    }

    return page404.value?.seo?.meta_title || page404.value?.title || 'Pagina niet gevonden';
  }),
});

type AccessData = {
  type: string
  slug: string
}

const accessData = computed(() => props.error.data && typeof props.error.data === 'object' && 'type' in props.error.data && 'slug' in props.error.data
  ? props.error.data as AccessData
  : null);

const {result: checkAccessRequestResult} = useCheckAccessRequestQuery(() => ({
  type: accessData.value?.type ?? '',
  slug: accessData.value?.slug ?? '',
}), {
  enabled: !!user.value && !!accessData.value,
});
const checkAccessRequest = computed(() => checkAccessRequestResult.value?.checkAccessRequest ?? false);

const {mutate: requestAccess} = useRequestAccessToMaterialOrProjectMutation(() => ({
  variables: {
    type: (props.error.data as AccessData).type,
    slug: (props.error.data as AccessData).slug,
  },
  refetchQueries: [CheckAccessRequestDocument],
}));
</script>
