import validate from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.13_op5zjsnrevxet44euqkvejsbyi/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_t4zzmqtzrwgnhgl3rajzqbvvr4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_lkx5sjnhhbrkv73b5ok7hn4mea/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/marktplaats.insert.nl/insert/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+core@7.25.2_reac_wadxvwj4acgyvaoc7pvfuyb7pq/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}